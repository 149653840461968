<template>
  <div>
    <currentLocation :sibItem="sibItem"></currentLocation>
    <div class="outerOne">
      <div>
        <img src="@/assets/img/dsBJ1.png" alt="" />
        <div>
          <div class="oneTitle">
            {{ $t("designProductDepartment.DPD_oneTitle") }}
          </div>
          <div class="oneCont">
            {{ $t("designProductDepartment.DPD_oneCont") }}
          </div>
        </div>
      </div>
    </div>
    <div class="outerTwo">
      <div class="twoCont">
        <div class="twoTip">
          <div
            class="twoTipItem"
            v-for="item in $t('designProductDepartment.DPD_twoTip')"
            :key="item"
          >
            <img src="@/assets/img/redDui.png" alt="" />
            <div>{{ item }}</div>
          </div>
        </div>
        <img src="@/assets/img/DPDimg1.png" alt="" />
      </div>
    </div>
    <div class="outerThree">
      <div class="threeTip">
        {{ $t("designProductDepartment.DPD_threeTip") }}
      </div>
      <img src="@/assets/img/DPDimg22.png" alt="" />
    </div>

    <div class="outerFour">
      <div class="fourTip fourTipOne">
        {{ $t("designProductDepartment.DPD_fourTipOne") }}
      </div>
      <div class="fourCont fourContOne">
        <div class="fContOne">
          <div class="fContOne1">
            {{ $t("designProductDepartment.DPD_fourContOne") }}
          </div>
          <div class="fContOne2">
            {{ $t("designProductDepartment.DPD_fourContOneOne") }}
          </div>
        </div>
        <img src="@/assets/img/DPDimg3.png" alt="" class="DPDimg3" />
      </div>

      <div class="fourTip fourTipTwo">
        {{ $t("designProductDepartment.DPD_fourTipTwo") }}
      </div>
      <div class="fourCont fourContTwo">
        <div class="fContTwo">
          {{ $t("designProductDepartment.DPD_fourContTwo") }}
        </div>
        <img src="@/assets/img/DPDimg4.png" alt="" class="DPDimg4" />
      </div>

      <div class="fourTip fourTipThree">
        {{ $t("designProductDepartment.DPD_fourTipThree") }}
      </div>
      <div class="fourCont fourContThree">
        <div class="fContTwo">
          {{ $t("designProductDepartment.DPD_fourContThree") }}
        </div>
      </div>

      <div class="fourTip fourTipFour">
        {{ $t("designProductDepartment.DPD_fourTipFour") }}
      </div>
      <div class="fourCont fourContFour">
        <div class="fContTwo">
          {{ $t("designProductDepartment.DPD_fourContFour") }}
        </div>
        <!-- <div class="fContTwo">
          {{ $t("designProductDepartment.DPD_fourContFourOne") }}
        </div> -->
        <img src="@/assets/img/DPDimg5.png" alt="" class="DPDimg5" />
      </div>

      <div class="fourTip fourTipFive">
        {{ $t("designProductDepartment.DPD_fourTipFive") }}
      </div>
      <div class="fourCont fourContFive">
        <div class="fContOne fContFive">
          <div class="fContTwo">
            {{ $t("designProductDepartment.DPD_fourContFiveOne") }}
          </div>
          <div class="fContThree">
            <div>
              {{ $t("designProductDepartment.DPD_fourContFiveTwo") }}
            </div>
            <img src="@/assets/img/DPDimg6.png" alt="" class="DPDimg6" />
          </div>
        </div>
        <div class="fContTwo fContFive">
          {{ $t("designProductDepartment.DPD_fourContFiveThree") }}
        </div>
        <div class="fContFiveFour">
          {{ $t("designProductDepartment.DPD_fourContFiveFour") }}
        </div>

        <div class="fContFiveModular">
          <div
            class="fiveItem"
            v-for="(item, index) in $t(
              'designProductDepartment.DPD_fourContFiveFive'
            )"
            :key="index"
          >
            <img :src="item.img" alt="" />
            <div v-html="item.name"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/**
 * Created by www.xingnuocn.com
 * @title：设计与产品部
 *
 * @author: 刘嘉鑫
 * @version  V1.0
 * @datecreate: 2022-09-27 17:01
 */
import currentLocation from "@/components/currentLocation.vue";
export default {
  components: {
    currentLocation,
  },
  data() {
    return {
      sibItem: [
        {
          title_zh: "技术研发",
          title_en: "Technology R & D",
          path: "TechnologyRD",
        },
        {
          title_zh: "部门设置",
          title_en: "Department settings",
          path: "departmentSettings",
        },
        {
          title_zh: "设计与产品部",
          title_en: "Design and Product Department",
          path: "",
        },
      ],
    };
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScrollbox, true);
  },
  mounted() {
    window.addEventListener("scroll", this.handleScrollbox, true);
    this.handleScrollboxNow();
  },
  methods: {
    /**
     * 动画效果
     * 刘嘉鑫
     * 2022-9-29
     */
    handleScrollboxNow() {
      this.currentScroll = window.pageYOffset; //表示当前滚动的位置
      if (this.currentScroll >= this.$(".outerOne").offset().top - 950) {
        this.$(".outerOne>div").addClass("showDiv");
      }
    },

    handleScrollbox() {
      this.currentScroll = window.pageYOffset; //表示当前滚动的位置

      if (this.currentScroll >= this.$(".threeTip").offset().top - 900) {
        this.$(".threeTip").addClass("showDiv");
      }

      if (this.currentScroll >= this.$(".fourTipOne").offset().top - 800) {
        this.$(".fourTipOne").addClass("showDiv");
      }

      if (this.currentScroll >= this.$(".fourContOne").offset().top - 580) {
        this.$(".fourContOne").addClass("showDiv");
      }

      if (this.currentScroll >= this.$(".fourTipTwo").offset().top - 800) {
        this.$(".fourTipTwo").addClass("showDiv");
      }

      if (this.currentScroll >= this.$(".fourContTwo").offset().top - 580) {
        this.$(".fourContTwo").addClass("showDiv");
      }

      if (this.currentScroll >= this.$(".fourTipThree").offset().top - 800) {
        this.$(".fourTipThree").addClass("showDiv");
      }

      if (this.currentScroll >= this.$(".fourContThree").offset().top - 580) {
        this.$(".fourContThree").addClass("showDiv");
      }

      if (this.currentScroll >= this.$(".fourTipFour").offset().top - 600) {
        this.$(".fourTipFour").addClass("showDiv");
      }

      if (this.currentScroll >= this.$(".fourContFour").offset().top - 580) {
        this.$(".fourContFour").addClass("showDiv");
      }

      if (this.currentScroll >= this.$(".fourTipFive").offset().top - 600) {
        this.$(".fourTipFive").addClass("showDiv");
      }

      if (this.currentScroll >= this.$(".fourContFive").offset().top - 580) {
        this.$(".fourContFive").addClass("showDiv");
      }
    },
  },
};
</script>
<style lang='scss' scoped>
.appCont {
  background: #f6f6f6;
}
.outerOne {
  padding: 80px 310px 40px;
  > div {
    display: flex;
    align-items: flex-start;
    transition: all 0.7s ease-in 0s;
    transform: translateY(20px);
    opacity: 0;
    img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      margin-right: 30px;
    }

    div {
      .oneTitle {
        font-size: 28px;
        font-weight: bold;
        color: #222222;
        margin-bottom: 30px;
      }
      .oneCont {
        font-size: 18px;
        font-weight: 400;
        color: #222222;
        line-height: 32px;
      }
    }
  }
}

.outerTwo {
  padding: 80px 310px;
  background: #ffffff;
  .twoCont {
    position: relative;
    width: 100%;
    // height: 473px;
    > img {
      width: 100%;
      height: 100%;
      object-fit: inherit !important;
    }

    .twoTip {
      background: #eeeeee;
      padding: 20px 40px 0;
      width: 71.5%;
      border-radius: 4px;
      position: absolute;
      top: 0;
      right: 0;

      .twoTipItem {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        > img {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }

        > div {
          font-size: 18px;
          font-weight: bold;
          color: #1a2a60;
        }
      }
    }
  }
}

.outerThree {
  .threeTip {
    font-size: 28px;
    font-weight: bold;
    color: #222222;
    padding: 80px 310px 40px;
    transition: all 0.7s ease-in 0s;
    transform: translateY(20px);
    opacity: 0;
  }
  img {
    width: 100%;
    // height: 1159px;
    
  }
}

.outerFour {
  padding: 0 258px 45px;

  .fourTipOne,
  .fourContOne,
  .fourTipTwo,
  .fourContTwo,
  .fourTipThree,
  .fourContThree,
  .fourTipFour,
  .fourContFour,
  .fourTipFive,
  .fourContFive {
    transition: all 0.6s ease-in 0s;
    transform: translateY(30px);
    opacity: 0;
  }

  .fourTip {
    font-size: 28px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #222222;
    padding: 80px 50px 40px;
  }

  .fourCont {
    background: #ffffff;
    padding: 64px 52px 80px;

    .fContOne {
      display: flex;
      align-items: center;
      margin-bottom: 75px;
      .fContOne1 {
        font-size: 18px;
        font-weight: bold;
        color: #323232;
        margin-right: 20px;
      }
      .fContOne2 {
        font-size: 18px;
        font-weight: 400;
        color: #323232;
      }
    }

    .fContTwo {
      font-size: 18px;
      font-weight: 400;
      color: #414141;
      line-height: 35px;
    }

    .fContThree {
      background: #ededed;
      padding: 26px 30px;
      text-align: center;
      margin-left: 33px;
      div {
        font-size: 20px;
        font-weight: normal;
        color: #353535;
        margin-bottom: 25px;
      }
    }

    .fContFive {
      margin-bottom: 30px !important;
    }

    .fContFiveFour {
      font-size: 24px;
      font-weight: bold;
      color: #334993;
      margin-bottom: 36px;
      text-align: center;
    }

    .DPDimg3 {
      width: 87%;
      // height: 329px;
      margin: 78px auto 0;
      object-fit: inherit !important;
    }

    .DPDimg4 {
      width: 54%;
      // height: 430px;
      margin: 48px auto 0;
      object-fit: inherit !important;
    }

    .DPDimg5 {
      width: 42%;
      // height: 345px;
      margin: 48px auto 0;
      object-fit: inherit !important;
    }

    .DPDimg6 {
      width: 419px;
      height: 194px;
    }

    .fContFiveModular {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .fiveItem {
        width: 300px;
        height: 130px;
        overflow: hidden;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        img {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          z-index: -1;
          transition: all 0.9s !important;
        }
        div {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #fefefe;
          line-height: 32px;
          text-align: center;
        }
      }

      .fiveItem:hover {
        > img {
          transform: scale(1.2) !important;
        }
      }
    }
  }
}
</style>